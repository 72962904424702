import React, { Component, ErrorInfo, ReactNode } from 'react';
import { Grid, Typography } from '@mui/material';
import Image from 'next/image';
import tel from '@/telemetry';
import { getErrMessage } from '@/telemetry/helpers';

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  // eslint-disable-next-line react/state-in-constructor
  public state: State = {
    hasError: false
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public static getDerivedStateFromError(_: Error): State {
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    tel.addCustomEvent(tel.CUSTOM_EVENT_NAME.ERROR.CATASTROPHIC, undefined, {
      err_msg: getErrMessage(error)
    });
    console.error('ErrorBoundary caught an error:', error, errorInfo);
  }

  public render(): ReactNode {
    const { hasError } = this.state;
    const { children } = this.props;
    const heartPizzaImage = {
      desktop: '/images/error-page-desktop.jpg'
    };

    return hasError ? (
      <>
        <Grid
          container
          justifyContent="center"
          direction="column"
          alignItems="center"
          data-testid="catastrophic-error-page"
        >
          <Image
            alt="heart shaped pizza"
            src={heartPizzaImage.desktop}
            height={250}
            width={300}
          />
          <Typography variant="h1">We&lsquo;re sorry</Typography>
          <Typography variant="body1">
            We ran into an error... guess not all problems can be solved with
            pizza. Please refresh the page and try again.
          </Typography>
        </Grid>
      </>
    ) : (
      children
    );
  }
}

export default ErrorBoundary;
