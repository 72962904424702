import { useEffect, useCallback } from 'react';
import { enums, useDecision } from '@optimizely/react-sdk';
import useAnalytics from '@/dataAnalytics/hooks/useAnalytics';
import { getOrInitializeOptimizely } from '../../optimizely/optimizely';

interface OptimizelyNotification {
    type: string;
    userId: string;
    attributes: Record<string, unknown>;
    decisionInfo: {
        decisionEventDispatcher: boolean;
        enabled: boolean;
        flagKey: string;
        ruleKey: string;
        variationKey: string;
    };
}

const useOptimizelyNotificationListener = () => {
    const analytics = useAnalytics();
    const optimizely = getOrInitializeOptimizely();
    const [{ enabled: optimizelyNotificationListenerEnabled }] = useDecision('fr-dtg-1572-optimizely-notification-listener-enabled');

    const handleNotification = useCallback((notification: OptimizelyNotification) => {
        const { decisionInfo } = notification;
        const { flagKey, ruleKey, variationKey, enabled } = decisionInfo;

        const analyticsData = {
            event: `optimizely_exposure_${flagKey}`,
            experimentData: {
                flagKey,
                ruleKey,
                variationKey,
                enabled
            }
        };

        analytics.push(() => analyticsData);
    }, [analytics]);

    useEffect(() => {
        if (!optimizely || !optimizelyNotificationListenerEnabled) return undefined;

        const notificationID = optimizely.notificationCenter.addNotificationListener(
            enums.NOTIFICATION_TYPES.DECISION,
            handleNotification
        );

        return () => {
            optimizely.notificationCenter.removeNotificationListener(notificationID);
        };
    }, [optimizely, handleNotification, optimizelyNotificationListenerEnabled]);
};

export default useOptimizelyNotificationListener;