import React from 'react';
import { Grid } from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import FooterSectionHeader from './footerSection/FooterSectionHeader';
import ResponsiveImageRender from '../common/ResponsiveImageRender';
import { getJoinedText } from '../common/string-formatter';
import { mobileStartBreakpoint } from '../materialUi/theme';
import fontStyles from '../common/fontStyles';
import AnchorLink from '../common/AnchorLink';
import AppStoreSection from './AppStoreSection';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      paddingLeft: '16px'
    }
  },
  socialMediaAnchorBox: {
    display: 'inline-block',
    marginRight: '16px'
  },
  socialMediaAnchorLink: {
    ...fontStyles.commonComponentSelectedState,
    display: 'block',
    padding: '5px'
  },
  socialMediaLinks: {
    margin: '45px 0',
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      marginTop: '29px',
      marginBottom: '25px'
    }
  },
  socialMediaIcon: {
    height: '24px',
    objectFit: 'contain'
  }
}));

interface SocialMediaLink {
  media: { image: Image; link: LinkContent };
  classes: { [key: string]: string; };
}

const SocialMediaLink = ({ media, classes }: SocialMediaLink) => (
  <Grid className={classes.socialMediaAnchorBox}>
    <AnchorLink
      {...media.link}
      tabIndex={0}
      testId={`footer-${getJoinedText(media.link.linkDisplayText)}-link`}
      className={classes.socialMediaAnchorLink}
      dataAnalyticsCategory="global_footer"
      dataAnalyticsAction={`${media.link.linkDisplayText}_icon`.toLowerCase()}
    >
      <ResponsiveImageRender
        testId={`footer-${getJoinedText(media.link.linkTitle)}-icon`}
        mobile={media.image.mobile}
        desktop={media.image.desktop}
        altText="Social Media Icon"
        title={media.image.title}
        className={classes.socialMediaIcon}
      />
    </AnchorLink>
  </Grid>
);

const MobileAppAndSocialMedia = ({
  heading,
  externalLink,
  apple,
  google,
  facebook,
  instagram,
  youtube,
  twitter
}: MobileAppSocialMedia) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <FooterSectionHeader sectionName={heading} />
      <AppStoreSection apple={apple} google={google} externalLink={externalLink} />
      <div className={classes.socialMediaLinks}>
        <SocialMediaLink media={facebook} classes={classes} />
        <SocialMediaLink media={instagram} classes={classes} />
        <SocialMediaLink media={youtube} classes={classes} />
        <SocialMediaLink media={twitter} classes={classes} />
      </div>
    </div>
  );
};

export default MobileAppAndSocialMedia;
