import React, { useEffect, useRef, useState } from 'react';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { useDecision } from '@optimizely/react-sdk';
import AnchorLink from '../../common/AnchorLink';
import useStyles from '../styles';
import SignedInProfile from './signedInProfile';
import useWindowSize from '../../common/useWindowSize';
import ProfileSection from './profileSection';
import ProfileSkeleton from './ProfileSkeleton';
import { toggleRail } from '@/rail/slices/Rail.slice';
import { RootState } from '@/rootStateTypes';
import { RailType } from '@/rail/slices/Rail.slice.types';
import SignInRail from '@/rail/railContent/signInRail';
import { userDomainSelectors } from '../userDomainSelectors';
import { AuthState } from '@/auth/userStates';
import Routes from '@/router/routes';
import { cartSelectors } from '@/cart/cartSelectors';

interface ProfileProps {
  profileLinks: ProfileLinks;
  upgradeToRewards: UpgradeToRewards;
  profileIcons: HeaderProfileIcons;
}

const Profile = ({
  profileLinks: { signIn, ...profileLinks },
  upgradeToRewards,
  profileIcons
}: ProfileProps): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const router = useRouter();
  const gridRef = useRef<HTMLDivElement>(null);
  const windowSize = useWindowSize();
  const [open, setOpen] = useState(false);
  const [isLoggedInStatusLoading, setIsLoggedInStatusLoading] = useState(true);
  const customerDetails = useSelector((state: RootState) => state.presentational.header.customerDetails);
  const isLoggedIn = useSelector(userDomainSelectors.isAuthenticated);
  const loginStatus = useSelector(userDomainSelectors.loginStatus);
  const numberOfItemsInCart = useSelector(cartSelectors.quantity);
  const [signinGlowDecision] = useDecision('ops-web1669-enable_sign_in_glow');

  const isOnHomePage = router.route === Routes.HOME;
  const willGlow = isOnHomePage && numberOfItemsInCart === 0 && !isLoggedIn && signinGlowDecision.enabled;

  const toggleOpen = () => {
    setOpen((isOpen) => !isOpen);
  };
  const handleClose = () => setOpen(false);

  const handleToggleRail = () => dispatch(toggleRail(RailType.SIGN_IN));

  useEffect(() => {
    setIsLoggedInStatusLoading(loginStatus === AuthState.UNKNOWN);
  }, [loginStatus, isLoggedInStatusLoading]);

  useEffect(() => {
    if(gridRef.current && willGlow) {
      gridRef.current.classList.add(classes.profileGlow );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classes.profileGlow, gridRef]);

  if (isLoggedInStatusLoading) {
    return <ProfileSkeleton />;
  }

  return (
    <Grid item ref={gridRef} className={classes.profileWrapper} data-testid='profile-header-grid'>
      {isLoggedIn
        ? (
          <SignedInProfile
            classes={classes}
            profileLinks={profileLinks}
            upgradeToRewards={upgradeToRewards}
            isHutRewardsMember={customerDetails?.hutRewardsMember}
            open={open}
            gridRef={gridRef}
            toggleOpen={toggleOpen}
            handleClose={handleClose}
            profileSectionProps={{
              signIn, profileIcons, windowSize, customerDetails
            }}
          />
        )
        : (
          <Grid
            className={classes.signInProfileGrid}
            aria-label="Sign in button."
            aria-haspopup
          >
            {/* @ts-expect-error - existing code */}
            <AnchorLink
              tabIndex={0}
              testId="header-sign_in_join-link"
              className={classes.signInProfileLink}
              dataAnalyticsCategory="global_header"
              dataAnalyticsAction={signIn?.linkDisplayText}
              preventHref
              onClick={handleToggleRail}
              {...signIn}
            >
              <ProfileSection
                // @ts-expect-error - existing code
                signIn={signIn}
                profileIcons={profileIcons}
                classes={classes}
                windowSize={windowSize}
                customerDetails={customerDetails}
                open={open}
              />
            </AnchorLink>
            <SignInRail />
          </Grid>
        )}
    </Grid>
  );
};

export default Profile;
