import React from 'react';
import { Grid, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import fontStyles from '../common/fontStyles';
import { smallMobileStartBreakpoint, mobileStartBreakpoint } from '../materialUi/theme';
import colors from '../common/colors';
import borders from '../common/borders';
import { computeComponentKey, getJoinedText } from '../common/string-formatter';
import AnchorLink from '../common/AnchorLink';
import { ShownOnDevice } from '../common/ResponsiveContext';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    border: '0',
    borderTop: borders.gray400Border,
    marginTop: '40px',
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      borderTop: 'none',
      margin: '16px'
    }
  },
  legalLinks: {
    display: 'flex',
    marginTop: '18px',
    paddingBottom: '10px',
    borderBottom: `solid 1px ${colors.gray400}`,
    [theme.breakpoints.down(smallMobileStartBreakpoint)]: {
      flexDirection: 'column',
      margin: 0
    }
  },
  verticalLine: {
    height: '13px',
    margin: ' 5px 10px 5px',
    display: 'inline-block',
    borderLeft: `1px solid ${colors.gray900}`
  },
  linkDisplay: {
    display: 'inherit',
    height: '28px'
  },
  anchorLink: {
    ...fontStyles.footerTextLink,
    color: colors.gray900,
    marginBottom: '5px',
    textDecoration: 'none',
    paddingRight: '5px'
  },
  badgeText: {
    ...fontStyles.badgeText,
    lineHeight: 2,
    width: '35px',
    height: '20px',
    textAlign: 'center',
    color: colors.red,
    border: `1px solid ${colors.red}`,
    borderRadius: '3px'
  },
  disclaimerText: {
    ...fontStyles.bodySmall,
    marginTop: '18px',
    marginBottom: '25px'
  }
}));

const LegalDisclaimerFooter = ({ links, disclaimers }: LegalDisclaimer) => {
  const classes = useStyles();
  const isLastLink = links.length - 1;
  const getBadgeTextTestId = (badgeText: string, link: LinkContent) => `footer-${getJoinedText(`${badgeText}_badge ${link.linkDisplayText}`)}-link`;

  const revealCookieSettings = () => {
    const prefpc = document.getElementById("onetrust-pc-sdk");
    const filter = document.getElementsByClassName("onetrust-pc-dark-filter")[0];
    prefpc?.setAttribute("style", "display: block !important;animation: ot-slide-in-left .5s linear");
    filter?.setAttribute("style", "display: block !important;");
  };

  return (
    <div className={classes.root}>
      <div className={classes.legalLinks} data-testid="legal-declaimer-links">
        {links.map(({ link, badgeText, newFlag }, index) => (
          <React.Fragment
            key={computeComponentKey(link.linkDisplayText || '', index)}
          >
            <Grid className={classes.linkDisplay}>
              <AnchorLink
                {...link}
                tabIndex={0}
                className={classes.anchorLink}
                testId={`footer-${getJoinedText(link.linkDisplayText)}-link`}
                dataAnalyticsCategory="global_footer"
                dataAnalyticsAction={link.linkDisplayText}
              />
              {newFlag && (
                <Typography
                  className={classes.badgeText}
                  data-testid={getBadgeTextTestId(badgeText, link)}
                >
                  {badgeText}
                </Typography>
              )}
              <ShownOnDevice mdUp>
                {!(index === isLastLink) && (
                  <Typography
                    className={classes.verticalLine}
                    data-testid="vertical-line-link"
                  />
                )}
              </ShownOnDevice>
            </Grid>
          </React.Fragment>
        ))}
        <>
          <Typography
            className={classes.verticalLine}
            data-testid="vertical-line-link"
          />
          <AnchorLink
            tabIndex={0}
            className={classes.anchorLink}
            dataAnalyticsCategory="global_footer"
            dataAnalyticsAction="Cookie Settings"
            external={false}
            link="javascript:void(0);"
            linkDisplayText="Cookie Settings"
            onClick={revealCookieSettings}
          />
        </>
      </div>
      <Typography
        className={classes.disclaimerText}
        data-testid="footer-screen_reader_advisory-text"
      >
        {disclaimers.legalStatement}
      </Typography>
      <Typography
        className={classes.disclaimerText}
        data-testid="footer-copyright_advisory-text"
      >
        {disclaimers.legalCopyright}
      </Typography>
    </div>
  );
};

export default LegalDisclaimerFooter;
