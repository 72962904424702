import { QueryResult, ApolloError } from '@apollo/client';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import TypedQuery, { TypedQueryWithParser } from '../types/TypedQuery';
import useSSRQuery from './useSSRQuery';
import useQueryVariables from './variables/useQueryVariables';
import useGqlVersion from './useGqlVersion';
import { CustomQueryOptions } from '../types/QueryOptions';
import { ProductDomain } from './variables/useProductItem';
import { GqlVersion } from './useGqlVersion/GqlVersion';
import logger from '@/common/logger';
import { hideLoadingQuery, showLoadingQuery } from '@/localization/actions';

type QueryFunction = (version: GqlVersion) => TypedQuery<unknown>;
export type QueryFunctionWithParser<ParsedResultType, RawQueryResultType, ParserProps> =
  (version: GqlVersion) => TypedQueryWithParser<ParsedResultType, RawQueryResultType, ParserProps>;

export interface VersionQuery<T> extends QueryResult<T> {
  storeID: string | null;
  completedFinalRetryAndHasError: boolean;
}
interface UseVersionQueryProps<ParsedResultType, RawQueryResultType = never, ParserProps = never> {
  queryFn: QueryFunction
  | QueryFunctionWithParser<ParsedResultType, RawQueryResultType, ParserProps>;
  storeSpecific?: boolean;
  itemId?: string;
  options?: CustomQueryOptions;
  nationalOverrides?: boolean;
  productDomain?: ProductDomain;
  parserOptions?: ParserProps | unknown;
  maxNumberOfRetries?: number;
}

export function useVersionQuery<ParsedResultType, RawQueryResultType = never, ParserProps = never>({
  queryFn,
  storeSpecific = true,
  itemId,
  options,
  nationalOverrides = false,
  productDomain,
  parserOptions,
  maxNumberOfRetries = 0
} : UseVersionQueryProps<
ParsedResultType, RawQueryResultType, ParserProps
>): VersionQuery<ParsedResultType> {
  const version = useGqlVersion();
  const dispatch = useDispatch();
  const hasNationalOverrides = nationalOverrides && version === 'v3';
  const { query, parser } = queryFn(version);
  const { variables, skip, storeID } = useQueryVariables(
    itemId,
    storeSpecific,
    options,
    hasNationalOverrides,
    productDomain
  );
  // this is a workaround because of this issue https://github.com/apollographql/apollo-client/issues/9169
  const params = { skip, ...variables, notifyOnNetworkStatusChange: Boolean(maxNumberOfRetries) };
  const unParsed = useSSRQuery(query, params);
  const unParsedWithStore = {
    ...unParsed,
    ...{ storeID: (storeSpecific && storeID) ? storeID : null }
  };
  const [numberOfRetries, setNumberOfRetries] = useState(0);
  const [isLoading, setLoading] = useState(true);
  const [hasLoggedError, setLoggedError] = useState(false);

  useEffect(() => {
    if (!skip) {
      dispatch(isLoading ? showLoadingQuery() : hideLoadingQuery());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const hasCompletedFinalRetry = useRef(false);
  useEffect(() => {
    if (numberOfRetries > 0 && numberOfRetries <= maxNumberOfRetries) {
      unParsed.refetch();
      hasCompletedFinalRetry.current = numberOfRetries === maxNumberOfRetries;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numberOfRetries]);

  useEffect(() => {
    if (numberOfRetries < maxNumberOfRetries
        && ((!unParsed.loading && !unParsed.data) || unParsed.error)) {
      setNumberOfRetries((retryCount) => retryCount + 1);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unParsed.loading]);

  if (options?.skip) {
    return {
      ...unParsedWithStore,
      data: undefined,
      error: undefined,
      completedFinalRetryAndHasError: false
    };
  }

  let parsed: VersionQuery<ParsedResultType>;
  try {
    if (!unParsedWithStore.data && !unParsedWithStore.loading && !unParsedWithStore.error) {
      throw new ApolloError({ errorMessage: 'no data available' });
    }
    parsed = {
      ...unParsedWithStore,
      data: unParsedWithStore.data
        ? parser(unParsedWithStore.data, parserOptions as ParserProps) as ParsedResultType
        : undefined,
      completedFinalRetryAndHasError: false
    };
  } catch (parserError) {
    if (!maxNumberOfRetries) {
      unParsed.client.clearStore();
    }
    let error: ApolloError;
    if (parserError instanceof ApolloError) {
      error = parserError;
    } else if (parserError instanceof Error) {
      error = new ApolloError({ errorMessage: `failed to parse data: ${parserError.message}` });
    } else {
      error = new ApolloError({ errorMessage: `failed to parse data: ${parserError}` });
    }
    if (!hasLoggedError) {
      logger.withoutTelemetry.error('GQL query failed', error);
      setLoggedError(true);
    }
    parsed = {
      ...unParsedWithStore,
      data: undefined,
      error,
      completedFinalRetryAndHasError: hasCompletedFinalRetry.current && !!error
    };
  }

  if (parsed.loading !== isLoading) {
    setLoading(parsed.loading);
  }

  return parsed;
}
