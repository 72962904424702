import { useEffect } from 'react';
import SplunkOtelWeb from '@splunk/otel-web';
import getConfig from 'next/config';

const SplunkSPAAgent = (): void => {
  const { publicRuntimeConfig } = getConfig();
  const { SPLUNK_RUM_TOKEN, ENVIRONMENT_NAME } = publicRuntimeConfig;

  useEffect(() => {
    if (!SplunkOtelWeb.inited) {
      const splunkEnviromentNaming = {
        local: 'Local',
        development: 'Dev',
        staging: 'Staging',
        dark: 'Dark',
        prod: 'Prod'
      };

      SplunkOtelWeb.init({
        realm: 'us1',
        rumAccessToken: SPLUNK_RUM_TOKEN,
        applicationName: 'web',
        version: '0.1.0',
        deploymentEnvironment: splunkEnviromentNaming[ENVIRONMENT_NAME as keyof typeof splunkEnviromentNaming] ?? 'Unknown'
      });
    }
  }, [SPLUNK_RUM_TOKEN, ENVIRONMENT_NAME]);
};

export default SplunkSPAAgent;
