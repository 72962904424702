import { AnyAction, Store } from 'redux';
import { setLastActiveTimestamp } from './actions';
import isClientSide from '../common/isClientSide';
import { UserAuthentication } from '@/common/pageSetup';
import { initializeAuthTokenHelpers } from './authTokenHelpers';
import { RootState } from '@/rootStateTypes';
import { YumRefreshTokenData } from '@/services/authService';

export function init(
  store: Store<RootState, AnyAction>,
  localized: boolean = false,
  localizationToken: string | undefined,
  userAuthentication?: UserAuthentication,
  yumAuthRefreshData?: YumRefreshTokenData,
  onAfterInit?: () => void
): void {
  if (!isClientSide()) {
    // Refresh only works/matters client side, and the remaining functionality is unnecessary
    // server-side
    return;
  }

  store.dispatch(setLastActiveTimestamp(new Date().getTime()));

  initializeAuthTokenHelpers({
    getState: store.getState,
    dispatch: store.dispatch,
    localized, 
    initLocalizationToken: localizationToken,
    userAuthentication,
    shouldCreateCart: true,
    shouldGetCart: true,
    yumAuthRefreshData,
    onAfterInit
  });
}
