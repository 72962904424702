export const CUSTOM_EVENT_NAMES = {
    ACCOUNT: {
        IFRAME: {
            INIT: 'account.iframe.init'
        },
        LOGIN: 'account.login',
        LOGOUT: 'account.logout',
        LOYALTY: {
            REDEEM: 'account.loyalty.redeem'
        },
        REGISTER: 'account.register',
        RESET_PASSWORD: 'account.reset_password',
        UPDATE: 'account.update'
    },
    AUTH: {
        GUEST: 'auth.guest',
        REGISTERED: 'auth.registered'
    },
    CART: {
        ADD: 'cart.add',
        CREATE: 'cart.create',
        DELETE: 'cart.delete',
        GET: 'cart.get',
        PROMO: {
            ADD: 'cart.promo.add',
            LOOKUP: 'cart.promo.lookup',
            REMOVE: 'cart.promo.remove'
        },
        UPDATE: 'cart.update'
    },
    // ERROR events will never have success or failures associated, since their existence is a 'failure'
    ERROR: {
        BAD_LINK: 'error.bad_link',
        CATASTROPHIC: 'error.catastrophic',
        GENERIC: 'error.generic',
        GOOGLE: {
            GEOCODER: 'error.google.geocoder',
            RECAPTCHA: 'error.google.recaptcha'
        },
        OPTIMIZELY: 'error.optimizely',
        PAGE: 'error.page',
        ROKT: 'error.rokt'
    },
    LOCALIZATION: {
        CARRYOUT: {
            SEARCH: 'localization.carryout.search',
            SELECT: 'localization.carryout.select'
        },
        DELIVERY: {
            SEARCH: 'localization.delivery.search',
            SELECT: 'localization.delivery.select'
        }
    },
    MENU: {
        DEAL: {
            BUILDER: 'menu.deal.builder',
            UNAVAILABLE: 'menu.deal.unavailable'
        },
        PIZZA: {
            BUILDER: 'menu.pizza.builder'
        },
        QUERY: 'menu.query'
    },
    ORDER: {
        PLACE_ORDER: 'order.place_order',
        STATUS: 'order.status',
        STORE_INFO: 'order.store_info'
    },
    PAYMENT: {
        IFRAME: {
            CAPTURE: 'payment.iframe.capture',
            INIT: 'payment.iframe.init'
        }
    }
};
