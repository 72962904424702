// eslint-disable-next-line no-restricted-imports
import 'isomorphic-unfetch';
import ConversationIDStore from '../../telemetry/conversationIdStore';
import logger from '../logger';

export enum ApiResponses {
  FAILED = 'API call failed',
  SUCCESS = 'API call returned successfully',
  FAILED_WITH_CODE = 'API call returned with non-2xx status'
}

export type LoggingFetchParser<T> = (response: Response) => Promise<T>;

type LoggingFetchRequest = {
  requestUrl: RequestInfo;
  options?: RequestInit;
};

const loggingFetch = <T>(
  { requestUrl, options = {} }: LoggingFetchRequest,
  parser: LoggingFetchParser<T>
): Promise<T> => {
  const requestMethod = options?.method || 'GET';
  const requestHeaders = options?.headers || {};

  const requestOptions = {
    ...options,
    headers: {
      ...requestHeaders,
      conversationID: ConversationIDStore.get()
    }
  };

  return fetch(requestUrl, requestOptions)
    .then(async (response) => {
      const { status, url } = response;

      try {
        const parsedResponse = await parser(response);

        logger.withoutTelemetry.debug(ApiResponses.SUCCESS, {
          url,
          status,
          method: requestMethod
        });

        return parsedResponse;
      } catch (error) {
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw [error, response];
      }
    })
    .catch((reason: Error | [Error, Response]) => {
      if (reason instanceof Error) {
        logger.withoutTelemetry.error(ApiResponses.FAILED, {
          reason,
          request: requestUrl,
          options
        });

        throw reason;
      }

      const [error, response] = reason;

      const {
        url, status, body
      } = response;

      logger.withoutTelemetry.error(error.message, {
        url,
        status,
        body: body?.toString(),
        method: requestMethod
      });

      throw error;
    });
};

export default loggingFetch;
