import { Grid, MenuItem } from '@mui/material';
/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import { useRouter } from 'next/router';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import AnchorLink from '../../common/AnchorLink';
import { getJoinedText } from '../../common/string-formatter';
import { updateUrl } from '../../common/url';
import useStyles from '../styles';
import {
  ENTER_KEY_EVENT_CODE,
  KEYBOARD_ENTER_KEYCODE
} from '../../common/constants';
import { onMenuDropdownCategoryClick } from '@/dataAnalytics/dataAnalyticsHelper';
import { LinkTypes } from '@/common/AnchorLink/types';
import useAnalytics from '@/dataAnalytics/hooks/useAnalytics';
import { getOrInitializeOptimizely } from '../../../optimizely/optimizely';

const categoryListItem = (
  category: LinkContent,
  index: number,
  testId: string
) => {
  const classes = useStyles();
  const router = useRouter();
  const { linkType, link, linkDisplayText, linkTitle } = category || {};
  const analytics = useAnalytics();
  const optimizelyInstance = getOrInitializeOptimizely();

  const handleItemKeyDown = (event: any) => {
    const isRelativeLink = linkType === LinkTypes.RELATIVE_LINK;

    if (
      event.key === ENTER_KEY_EVENT_CODE ||
      event.keyCode === KEYBOARD_ENTER_KEYCODE
    ) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      isRelativeLink ? router.push(`/${link}`) : updateUrl(link);
    }
  };

  const onMenuItemClick = () => {
    analytics.push(() => onMenuDropdownCategoryClick(`${linkDisplayText}`));
    optimizelyInstance?.track(`${linkDisplayText}MenuClick`);
  };

  return (
    <MenuItem
      disableRipple
      className={classes.menuListItem}
      data-testid={`nav-${getJoinedText(linkDisplayText)}`}
      title={linkTitle}
      onKeyDown={handleItemKeyDown}
      onClick={onMenuItemClick}
      tabIndex={0}
      key={index}
    >
      <AnchorLink
        dataAnalyticsCategory="global_header"
        dataAnalyticsAction={linkDisplayText}
        className={classes.menuLink}
        testId={testId}
        tabIndex={-1}
        {...category}
      >
        <>
          <Grid
            item
            data-analytics-category="global_header"
            data-analytics-action={linkDisplayText}
          >
            {linkDisplayText}
          </Grid>
          <Grid>
            <ArrowForwardIosIcon
              fontSize="small"
              className={classes.caratIcon}
              data-testid={`${testId}-arrow`}
              data-analytics-category="global_header"
              data-analytics-action={linkDisplayText}
            />
          </Grid>
        </>
      </AnchorLink>
    </MenuItem>
  );
};

export default categoryListItem;
