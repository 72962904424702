import { Replacer } from '@/salesExperience/replacers/types';
import logger from '@/common/logger';

const map: Record<string, string> = {
  accountSettings: 'profile',
  orders: 'orders',
  hutRewards: 'rewards'
};

class AccountReplacerError extends Error {
  name = 'AccountReplacerError';
}

const account: Replacer = {
  match(url) {
    if (url.pathname !== '/link.php') {
      return false;
    }
    return url.searchParams.has('dashboard') || url.searchParams.has('hutrewardsRegistration');
  },

  replace(url) {
    // eslint-disable-next-line
    const dashboard = url.searchParams.get('dashboard');
    const hutRewardsRegistration = url.searchParams.has('hutrewardsRegistration');
    if (!dashboard && !hutRewardsRegistration) {
      const error = new AccountReplacerError(
        'The "dashboard" and "hutrewardsRegistration" params are missing'
      );
      logger.withoutTelemetry.error(String(error), url.toString());
      return url.originalUrl;
    }
    if (hutRewardsRegistration) {
      url.searchParams.delete('hutrewardsRegistration');
      return `${url.prefix}account/create-account`;
    }
    const page = dashboard ? map[dashboard] : undefined;
    if (!page) {
      const error = new AccountReplacerError('No web2 page associated with dashboard param');
      logger.withoutTelemetry.error(String(error), url.toString());
      return url.originalUrl;
    }
    url.searchParams.delete('dashboard');
    return `${url.prefix}account/${page}${url.search}`;
  }
};

export { account };
