const errorHasStatus = (e: unknown): e is { status?: number | string } => !!e && typeof e === 'object' && 'status' in e;
const errorHasCode = (e: unknown): e is { code?: string | number } => !!e && typeof e === 'object' && 'code' in e;
export const getStatusCode = (error: unknown): string => {
    if (errorHasStatus(error)) return error.status?.toString() ?? 'unknown';
    if (errorHasCode(error)) return error.code?.toString() ?? 'unknown';
    return 'unknown';
};

const errorHasMessage = (e: unknown): e is { message?: string } => !!e && typeof e === 'object' && 'message' in e;
export const getErrMessage = (error: unknown): string => {    
    if (typeof error === 'string') return error;
    if (errorHasMessage(error)) return error.message?.toString() ?? '';
    return '';
}
