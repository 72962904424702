import {
  useCallback, useEffect, useMemo, useRef, useState
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Cart, OrderActions, useGetCartQuery
} from '@pizza-hut-us-development/client-core';
import { useDecision } from '@optimizely/react-sdk';
import { useCreateCart } from '@/clientCore/cart/hooks/useCreateCart';
import { orderSelectors } from '@/clientCore/redux/selectors/orderSelectors';
import { toggleCartLoadingStatus } from '@/clientCore/redux/cart/CartSlice';
import { CartAlert } from '@/domain/cart/types';
import {
  addSortedItemPointer,
  clearSortedItemPointers,
  setCartChangedAlert
} from '@/clientCore/redux/rail/CartRailSlice';
import { presentationalRailSelectors } from '@/clientCore/redux/selectors/clientCorePresentational/rail/presentationalRailSelectors';
import { calculateCartQuantity, checkIfCartHasChanges } from '@/clientCore/cart/helpers';
import tel from '@/telemetry';
import { getErrMessage, getStatusCode } from '@/telemetry/helpers';

export const useGetCart = () => {
  const dispatch = useDispatch();

  const cartId = useSelector(orderSelectors.cartId);
  const currentCart = useSelector(orderSelectors.cart);
  const sortedItemPointers = useSelector(presentationalRailSelectors.sortedItemPointers);
  const cartChangedAlert = useSelector(presentationalRailSelectors.cartChangedAlert);

  const [{ enabled: cartItemsChangedAlertFixEnabled }] = useDecision('fr-web-4527-fix-cart-item-changed-alert');
  const [{ enabled: getCartSkipFixEnabled }] = useDecision('fr-web-4583-get_cart_skip_fix');

  const [forceRefetch, setForceRefetch] = useState(false);

  const [createCart] = useCreateCart();
  /**
   * Currently we only want to refetch a cart:
   * - On page load when we have an existing cart ID stored in a cookie.
   * - When navigating to checkout page to check the state of the cart before checking out.
   *
   * This prevents extra queries to GET /carts if there is already a cart in the client core redux state,
   * in addition to skipping if we don't have a cartId. This is required because the RTKQ GET /carts
   * query is invalidated after every cart mutation which causes this query to refetch.
   *
   * Cart state updates already happen after every mutation internally within client core,
   * so we don't need to manage this ourselves here.
   *
   * Consumers can call getCart to force the query to refetch the data
   * e.g app/clientCore/checkout/hooks/useCheckout.ts
   */
  const skip = useMemo(() => !cartId || (!!currentCart && !forceRefetch), [cartId, currentCart, forceRefetch]);
  // If we skip while a query is running, we won't get data back from that query
  const isQueryRunning = useRef(false);

  const {
    data,
    isLoading,
    isFetching,
    isSuccess,
    isError,
    isUninitialized,
    error,
    refetch
  } = useGetCartQuery(cartId, { skip: skip && !isQueryRunning.current });

  useEffect(() => {
    if (!getCartSkipFixEnabled) return;
    if (isFetching) {
      isQueryRunning.current = true;
    } else if (isSuccess || isError) {
      isQueryRunning.current = false;
    }
  }, [isFetching, isSuccess, isError, isQueryRunning, getCartSkipFixEnabled]);

  // Telemetry useEffect
  const isNewQuery = useRef(true);
  useEffect(() => {
    if (!isNewQuery.current && !isFetching) return;
    if (isFetching) {
      isNewQuery.current = true;
    } else if (isSuccess) {
      isNewQuery.current = false;
      tel.addCustomEvent(tel.CUSTOM_EVENT_NAME.CART.GET, 'success');
      
    } else if (error) {
      isNewQuery.current = false;
      tel.addCustomEvent(tel.CUSTOM_EVENT_NAME.CART.GET, 'failure', {
        http_code: getStatusCode(error),
        err_msg: getErrMessage(error)
      });
      
    }
  }, [isFetching, isSuccess, error]);

  useEffect(() => {
    if (!cartId) dispatch(clearSortedItemPointers());
  }, [dispatch, cartId]);

  const dispatchAlertIfCartChanged = useCallback((updatedCart: Cart) => {
    if (!currentCart || !updatedCart) {
      return;
    }

    if (cartItemsChangedAlertFixEnabled) {
      if (cartChangedAlert) dispatch(setCartChangedAlert(null))
      checkIfCartHasChanges(currentCart.items, updatedCart, dispatch);
      return;
    }

    const orderItemQuantityChanged = calculateCartQuantity(currentCart.items) !== calculateCartQuantity(updatedCart.items);
    const orderTotalPriceChanged = currentCart.total !== updatedCart.total;

    if (orderItemQuantityChanged || orderTotalPriceChanged) {
      const cartAlert: CartAlert = {
        displayAlert: true,
        quantityChanged: orderItemQuantityChanged,
        previousPrice: currentCart.total,
        currentPrice: updatedCart.total,
        itemsRemoved: [] // TODO: where does this come from
      };
      dispatch(setCartChangedAlert(cartAlert));
    }
  }, [cartChangedAlert, currentCart, dispatch, cartItemsChangedAlertFixEnabled]);

  useEffect(() => {
    if (!isSuccess || !data) {
      return;
    }
    
    if (data.cartId !== cartId) {
      createCart({
        shouldResetYumCart: true
      });
    }

    setForceRefetch(false);
    dispatch(OrderActions.setCart(data));
    dispatchAlertIfCartChanged(data);

    if (!sortedItemPointers.length && data.items.length) {
      // store initial sort order for existing cart
      data.items.forEach((item) => dispatch(addSortedItemPointer({ cartItemId: item.cartItemId, itemId: item.id })));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isSuccess, data]);

  useEffect(() => {
    const isGetCartLoadingOrFetching = isLoading || isFetching;
    dispatch(
      toggleCartLoadingStatus({ loadingState: isGetCartLoadingOrFetching })
    );
  }, [dispatch, isLoading, isFetching]);

  const getCart = useCallback(async () => {
    if (isUninitialized) {
      // can't refetch a query if it's not been started
      setForceRefetch(true);
      return;
    }

    try {
      const result = await refetch();
      if (!result.data) {
        return;
      }

      dispatchAlertIfCartChanged(result.data);
    } catch (e) {
      console.error(e);
    }
  }, [dispatchAlertIfCartChanged, isUninitialized, refetch]);

  return [{
    data,
    isLoading,
    isFetching,
    isSuccess,
    isError,
    isUninitialized,
    error,
    hasCartId: cartId
  }, { getCart }];
};
